import { template as template_a68aa5b8a1ee4848a21275b8ff606361 } from "@ember/template-compiler";
const FKText = template_a68aa5b8a1ee4848a21275b8ff606361(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
