import { template as template_cc6ebb64a0ac407aab371bfd9659e0a9 } from "@ember/template-compiler";
const WelcomeHeader = template_cc6ebb64a0ac407aab371bfd9659e0a9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
