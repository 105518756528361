import { template as template_d33b2e6de6b74b42a24ea77a217506dc } from "@ember/template-compiler";
const SidebarSectionMessage = template_d33b2e6de6b74b42a24ea77a217506dc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
