import { template as template_b62bc9d3a5644a71bd9a1e5e8a46427a } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_b62bc9d3a5644a71bd9a1e5e8a46427a(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
