import { template as template_1968ab9e0e3342a29d231b0d5343e391 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_1968ab9e0e3342a29d231b0d5343e391(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
